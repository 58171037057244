export const useUserPreferenceStore = defineStore("userPreferenceStore", {
  state: () => ({
    userPreference: null,
  }),
  getters: {
    getUserPreference() {
      return this.userPreference;
    },
  },
  actions: {
    setUserPreference(data) {
        this.userPreference = data
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserPreferenceStore, import.meta.hot));
}
